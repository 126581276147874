import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { FooterSection, EmailLink } from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  return (
    <FooterSection>
      <Container>
        <Row justify="center">
          <Col lg={50} md={50} sm={50} xs={50} style={{ textAlign: "center" }}>
            <EmailLink href="mailto:info@aura.net.ru">info@aura.net.ru</EmailLink>
          </Col>
        </Row>
      </Container>
    </FooterSection>
  );
};

export default withTranslation()(Footer);
